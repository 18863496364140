import React from "react"
import { Grid } from "@material-ui/core"
import { useStyles } from "../theme/theme"
import Layout from "../components/layout"
import flag_images from "../components/FlagsImages"
import Features from "../components/features"
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Swiper, SwiperSlide } from "swiper/react"
import PhoneIcon from "@material-ui/icons/Phone"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import EmailIcon from "@material-ui/icons/Email"
import BusinessIcon from "@material-ui/icons/Business"
import AboutLottie from "../lottie/About.json"
import Test2 from "../assets/test2.jpg"
import Lottie from "react-lottie"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "../components/Slider/Slider.css"
import "../components/Slider/Slider.css"
import MapBox from "../assets/map_box.png"
import HeroImage from "../assets/BlueCrab.png"
import ContactUs from "../components/Contactus"
import MapImage from "../assets/map.png"
import OfficeSlider from "../components/Slider/OfficeImagesSlider"

function About() {
  const classes = useStyles()
  const theme = useTheme()
  const isMedSmall = useMediaQuery(theme.breakpoints.down(1023))
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isMob = useMediaQuery(theme.breakpoints.down(500))
  const isXs = useMediaQuery(theme.breakpoints.down(400))
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))
  const isXtraSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMed = useMediaQuery(theme.breakpoints.down(1280))

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AboutLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }




  return (
    <Layout>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={0}
        style={{ position: "relative" }}
      >
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          className={classes.about_section_header}
          data-sal="slide-left"
          data-sal-delay="0"
          data-sal-duration="1000"
          data-sal-easing="ease-out-back"
        >
          <h1>About us</h1>
          <p>
            Founded 10 years ago, Ocean Fishery has grown from a two people team selling one product to a team of 50+ individuals. Dedicated solely to provide you with the highest quality of seafood that supports local industries and instills trust in our customers.
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          className={classes.about_banner_image}
          data-sal="zoom-in"
          data-sal-delay="0"
          data-sal-duration="1000"
          data-sal-easing="ease-out-back"
        >
          {/* <Lottie options={defaultOptions} height={400} /> */}

          <img src={Test2} alt="Ocean fishery about us" />
        </Grid>
      </Grid>
      {/* <Grid container direction="row" justify="center" alignItems="center" >
        <Grid item xs={12} sm={12} md={10} className={classes.deliver_section}>
          <h1>What we promise to deliver ?</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </Grid>
      </Grid> */}
      <Features isXs={isXs} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ marginTop: "50px" }}
      >
        {/* <Grid item xs={10} sm={10} md={5} style={{ paddingLeft: "0px" }}>
          <div className={classes.pakOffice}>
            <h3>The pakistan office</h3>
            <h2>Fishery and Processing</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <ul className={classes.office_details}>
              <li>
                <PhoneIcon className={classes.office_icon}/>
                <p>+92 21 32331930</p>
              </li>
              <li>
                <PhoneAndroidIcon className={classes.office_icon}/>
                <p>+92 312 2012713</p>
              </li>
              <li>
                <EmailIcon className={classes.office_icon}/>
                <p>info@oceanfishry.com.pk</p>
              </li>
              <li>
                <BusinessIcon className={classes.office_icon}/>
                <p>Plot No. 7, Boat Building Yard,<br/>
West Wharf, Karachi</p>
              </li>
            </ul>
          </div>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={10}
          style={{ padding: "0px" }}
          data-sal="slide-left"
          data-sal-delay="0"
          data-sal-duration="1000"
          data-sal-easing="ease-out-back"
        >
          <div className={classes.thaiOffice}>
            <h3>The thailand office</h3>
            <h2>Distribution and Sourcing</h2>
            <p>
              In our Thailand office, each of our batches is carefully inspected
              to ensure the highest quality of catch and top of its class safety
              standards before being shipped to our customers globally.
            </p>
            <ul className={classes.office_details}>
              <li>
                <PhoneIcon className={classes.office_icon} />
                <p><a href="https://wa.me/+6634440503?text=Hey!%20I%20am%20interested%20in%20buying%20something%20from%20your%20fishery.">+66-34-440-503</a></p>
              </li>
              <li>
                <PhoneAndroidIcon className={classes.office_icon} />

                <p><a href="https://wa.me/+66818802868?text=Hey!%20I%20am%20interested%20in%20buying%20something%20from%20your%20fishery.">+66-81-880-2868</a></p>
              </li>
              <li>
                <EmailIcon className={classes.office_icon} />
                <p><a href="mailto:info@oceanfishery.co.th">info@oceanfishry.co.th</a></p>
              </li>
              <li>
                <BusinessIcon className={classes.office_icon} />
                <p>
                  199/108 Moo 3 Ekachai Road, Nadee, <br />
                  Muang, Samut Sakhon 74000 Thailand
                </p>
              </li>
            </ul>
          </div>
        </Grid> */}
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.export_section}
      >
        <Grid item xs={12} sm={12} className="title">
          <h3>Countries We Work With</h3>
          <p>
            Harvested from the seas of Karachi and prepared in Thailand, ready
            to be exported worldwide. We ship to multiple countries across the
            world and are constantly expanding.
          </p>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "#F7F8FB",
          paddingTop: "50px"
        }}
      >
        <Swiper
          slidesPerView={isMob ? 1 : isXSmall ? 2 : isMedSmall ? 3 : 4}
          spaceBetween={30}
          centeredSlides={false}
          loop={true}
          loopedSlides={15}
          autoplay={{ delay: "2000" }}
          className="Swiper-flags"
        >
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.China} />
            </div>
            <h4>China</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Bahrain} />
            </div>
            <h4>Bahrain</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Indonesia} />
            </div>
            <h4>Indonesia</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Japan} />
            </div>
            <h4>Japan</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Korea} />
            </div>
            <h4>Korea</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Malaysia} />
            </div>
            <h4>Malaysia</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Singapore} />
            </div>
            <h4>Singapore</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Taiwan} />
            </div>
            <h4>Taiwan</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Thailand} />
            </div>
            <h4>Thailand</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.UAE} />
            </div>
            <h4>UAE</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Vietnam} />
            </div>
            <h4>Vietnam</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.USA} />
            </div>
            <h4>USA</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Oman} />
            </div>
            <h4>Oman</h4>
          </SwiperSlide>
        </Swiper>
        <OfficeSlider />
        <Grid
          container
          spacing={0}
          direction="row"
          justify={isSmall ? "center" : "start"}
          alignItems="center"
          className={classes.contact_section}

          style={{ paddingTop: "50px" }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={6}
            xl={6}
            className={classes.map_image}
          >
            {/* <img
              src={MapBox}
              alt="ocean fishry address"
              className={classes.map_box}
            /> */}
            <img src={MapImage} alt="ocean fishry map" className={classes.map} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={5}
            xl={5}
            className={classes.contact_form}

          >

            <ContactUs isSmall={isSmall} />
          </Grid>
          <div class="ocean">
            <div class="wave wave1"></div>
            <div class="wave wave2"></div>
            <div class="wave wave3"></div>
            <div class="wave wave4"></div>
          </div>
        </Grid>
      </div>
    </Layout>
  )
}

export default About
