import React from 'react';
import {Grid} from '@material-ui/core';
import { useStyles } from '../theme/theme';
import Shape1 from '../assets/products/Shape1.svg'
import Shape2 from '../assets/products/Shape2.svg'
import Shape3 from '../assets/products/Shape3.svg'
import Shape4 from '../assets/products/Shape4.svg'

export default function Features(isXs){
    const classes = useStyles()

    return(
        <>
        <Grid container direction="row" justify="center"alignItems="center" style={{backgroundColor:"#fff"}} className={classes.products_features}
        data-sal="slide-right"
        data-sal-delay="0"
        data-sal-duration="1000"
        data-sal-easing="ease-out-back"
        >
        <Grid lg={2} md={3} sm={5} xs={isXs ? 10 : 5} style={{position:"relative"}}>
                <div className={classes.feature}>
                    <img src={Shape1} />
                    <p>Import to our <br/>homebase in <strong>Thailand</strong></p>
                </div>
            </Grid>
            
            <Grid lg={2} md={3} sm={5} xs={isXs ? 10 : 5} style={{position:"relative"}}>
            <div className={classes.feature}>
                    <img src={Shape2} />
                    <p>Never Farmed or <br/>Genetically modified</p>
                </div>
            </Grid>
            <Grid lg={2} md={3} sm={5} xs={isXs ? 10 : 5} style={{position:"relative"}}>
            <div className={classes.feature}>
                    <img src={Shape3} />
                    <p>Sustainably <br/>Harvested</p>
                </div>
            </Grid>
            <Grid lg={2} md={3} sm={5} xs={isXs ? 10 : 5} style={{position:"relative"}}>
            <div className={classes.feature}>
                    <img src={Shape4} />
                    <p>Flash<br/> Frozen</p>
                </div>
            </Grid>
        </Grid>
        </>
    )
}