import HeadofficeImage1 from '../assets/headofficeImages/office.jpg';
import HeadofficeImage2 from '../assets/headofficeImages/Office_outside.jpg';
import FactoryImage1 from '../assets/factoryImages/Factory_banner_outside.jpg';
import FactoryImage2 from '../assets/factoryImages/factory_inside.jpg';
import FactoryImage3 from '../assets/factoryImages/Octopus_cutting.jpg';
import FactoryImage4 from '../assets/factoryImages/Staff_working.jpg';

const headoffice_images = [
    { url: HeadofficeImage1 },
    { url: HeadofficeImage2 }
]

const factory_images = [
    { url: FactoryImage1 },
    { url: FactoryImage2 },
    { url: FactoryImage3 },
    { url: FactoryImage4 }
]

export default headoffice_images;
export { factory_images };
