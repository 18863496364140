import Bahrain from '../assets/Flags/Bahrain.png';
import China from '../assets/Flags/China.png';
import Indonesia from '../assets/Flags/Indonesia.png';
import Japan from '../assets/Flags/Japan.png';
import Korea from '../assets/Flags/Korea.jpg';
import Malaysia from '../assets/Flags/Malaysia.png';
import Singapore from '../assets/Flags/Singapore.png';
import Taiwan from '../assets/Flags/Taiwan.png';
import Thailand from '../assets/Flags/Thailand.png';
import UAE from '../assets/Flags/UAE.png';
import Vietnam from '../assets/Flags/Vietnam.png';
import Oman from '../assets/Flags/Oman.png';
import USA from '../assets/Flags/USA.png';

const flag_images = {
    Bahrain,
    China,
    Indonesia,
    Japan,
    Korea,
    Malaysia,
    Singapore,
    Taiwan,
    Thailand,
    UAE,
    Vietnam,
    USA,
    Oman
}
export default flag_images;