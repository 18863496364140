import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SwiperCore, {
    Pagination,
    Autoplay
} from 'swiper/core';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import './Slider.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import headoffice_images from '../LocationImages';
import { factory_images } from '../LocationImages';
import { Grid } from "@material-ui/core"
import { useStyles } from "../../theme/theme"


const OfficeSlider = () => {
    const theme = useTheme();
    const isXsSmall = useMediaQuery(theme.breakpoints.down("xs"))
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
    const isMed = useMediaQuery(theme.breakpoints.down("md"))
    const classes = useStyles()
    SwiperCore.use([Pagination, Autoplay]);

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={0}
                className={classes.locationSwiperContainer}


            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className={classes.locationDescription}
                >
                    <h3>
                        Head Office
                    </h3>
                    <p>
                        The office in Samut Sakhon, Thailand serves as our homebase for the management team, which consists of experienced professionals in the seafood industry. With their extensive knowledge and expertise, they oversee every aspect of the business, from sourcing the finest quality seafood to managing the processing and distribution of our products.
                    </p>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className={classes.locationSlider}
                >

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        loopedSlides={7}
                        autoplay={{ "delay": 2000 }}
                        className="mySwiper">
                        {headoffice_images.map(Image => (
                            <SwiperSlide className="swiper-slide4" >

                                <img src={Image.url} />

                            </SwiperSlide>
                        ))}


                    </Swiper>

                </Grid>


            </Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={0}
                className={classes.locationSwiperContainerFactory}

            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className={classes.locationSlider}
                >

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        loopedSlides={7}
                        autoplay={{ "delay": 2000 }}
                        className="mySwiper">
                        {factory_images.map(Image => (
                            <SwiperSlide className="swiper-slide4" >

                                <img src={Image.url} />

                            </SwiperSlide>
                        ))}


                    </Swiper>

                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className={classes.locationDescription}
                >
                    <h3>
                        Factory
                    </h3>
                    <p>
                        Our factory in Petchaburi is strategically located near the main port, which allows us to have quick and easy access to fresh seafood from various regions. This enables us to ensure the quality of our products while minimizing the time it takes to transport them to our processing facilities.
                    </p>
                </Grid>

            </Grid>
        </>

    )
}

export default OfficeSlider;



/* K7kBl6sNhfrlSTx8GK6msREsncJQ6QvN */